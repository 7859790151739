import * as React from "react";
import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import img_logo from "../assets/images/logo.png";
import PersonIcon from "@mui/icons-material/Person";
import {
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
  useMediaQuery,
} from "@mui/material";
import BookmarkIcon from "@mui/icons-material/Bookmark";
// import HomeIcon from "@mui/icons-material/Home";
import Cookies from "js-cookie";
import { handleLogOut } from "../slices/employSlice";
import LogoutIcon from "@mui/icons-material/Logout";
import { useNavigate, Link, useLocation } from "react-router-dom";
import LanguageMenu from "./LanguageMenu";
import { Translator, useLocalization } from "../lang";
import { useDispatch, useSelector } from "react-redux";
import { capitalize } from "../utils";
import { useFetch } from "../hooks";
import WorkIcon from "@mui/icons-material/Work";
import NotificationsIcon from "@mui/icons-material/Notifications";
import dayjs from "dayjs";
import IsSeeker from "./IsSeeker";
import IsEmployer from "./IsEmployer";
import HomeIcon from "@mui/icons-material/Home";
import { setOpen } from "../slices/slidbarSlice";
import useGuest from "../hooks/useGuest";
import SignInPrompt from "./SignInPrompt";
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // ...(open && {
  //   marginLeft: drawerWidth,
  //   width: `calc(100% - ${drawerWidth}px)`,
  //   transition: theme.transitions.create(["width", "margin"], {
  //     easing: theme.transitions.easing.sharp,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  // }),
  // marginRight: -drawerWidth,
  // ...(open && {
  //   transition: theme.transitions.create("margin", {
  //     easing: theme.transitions.easing.easeOut,
  //     duration: theme.transitions.duration.enteringScreen,
  //   }),
  //   marginRight: 0,
  // }),
}));

const Drawer = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    width: drawerWidth,
    minWidth: "4em",
    height: "100vh",
    overflow: "hidden",
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    // Enhanced layered shadow effect
    boxShadow: `
      0 2px 4px -1px rgba(0,0,0,0.01),
      0 4px 6px -1px rgba(0,0,0,0.05),
      0 10px 15px -3px rgba(0,0,0,0.05),
      4px 0 15px -3px rgba(0,0,0,0.05)
    `,
    paddingTop: "5em",
    position: "fixed",
    background: "rgba(255, 255, 255, 0.9)",
    backdropFilter: "blur(8px)",
    borderRight: "1px solid rgba(255, 255, 255, 0.3)",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  })
);

const StyledDrawer = styled(Box)(({ theme }) => ({
  "& .MuiDrawer-root": {
    "& .MuiDrawer-paper": {
      transition: "width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms !important",
      overflowX: "hidden !important",
      width: "calc(56px + 1px) !important",
      marginTop: "70px !important",
      marginLeft: "10px !important",
      border: "1px solid #b9b9b9 !important",
      borderRadius: "10px !important",
      height: "calc(100% - 80px) !important",
    },
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  width: "calc(100% - 1rem) !important",
  margin: "0.5rem !important",
  border: "1px solid #d4d4d4",
  borderRadius: "10px",
  backgroundColor: "#f7f7f7",
  overflow: "hidden",
}));

const Slider = ({ children }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const { translator } = useLocalization();
  const user = useSelector((state) => state.user);
  const open = useSelector((state) => state.slidbar.open);
  const location = useLocation();
  const isGuest = useGuest();
  const { type } = useSelector((state) => state.user);
  const [signInPrompt, setSignInPrompt] = useState(false);

  const { refetch } = useFetch("/auth/logout", {
    onSuccess: () => {
      Cookies.remove("token");
      dispatch(handleLogOut());
      navigate("/login");
    },
    enabled: false,
  });

  const handleDrawerOpen = () => {
    dispatch(setOpen(true));
  };

  const handleDrawerClose = () => {
    dispatch(setOpen(false));
  };

  const handleGuestNavigation = (path) => {
    if (isGuest) {
      setSignInPrompt(true);
    } else {
      navigate(path);
    }
  };

  return (
    <Box sx={{ display: "flex", position: "relative" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          background: "rgba(255, 255, 255, 0.8)",
          backdropFilter: "blur(8px)",
          // Enhanced layered shadow effect
          boxShadow: `
            0 2px 4px -1px rgba(0,0,0,0.01),
            0 4px 6px -1px rgba(0,0,0,0.05),
            0 10px 15px -3px rgba(0,0,0,0.05)
          `,
          borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
          "& .MuiToolbar-root": {
            minHeight: "70px",
          },
        }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {!isMobile && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={open ? handleDrawerClose : handleDrawerOpen}
                edge="start"
                sx={{
                  // position: "absolute",
                  // left: 26,
                  // marginRight: 5,
                  borderRadius: "50%",
                  background: "#a62639",
                  height: "2em",
                  width: "2em",
                  "&:hover": {
                    background: "#a62639",
                  },
                }}
                hover
              >
                {open ? (
                  theme.direction === "rtl" ? (
                    <ChevronRightIcon />
                  ) : (
                    <ChevronLeftIcon />
                  )
                ) : (
                  <MenuIcon />
                )}
              </IconButton>
            )}
            <Box
              sx={{
                display: "flex",
                marginInlineStart: "1em",
                // position: "absolute",
                // left: 60,
              }}
            >
              <img
                style={{ width: 70, marginLeft: 5, marginRight: 5 }}
                src={img_logo}
                alt="logo"
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "1em",
            }}
          >
            {/* Language menu */}
            {!isGuest && (
              <Box
                color="inherit"
                aria-label="open drawer"
                edge="start"
                sx={{
                  borderRadius: "50%",
                  width: "fit-content",
                  background: "#a62639",
                  "&:hover": {
                    background: "#a62639",
                  },
                }}
                hover
              >
                <LanguageMenu />
                {/* <PersonIcon /> */}
              </Box>
            )}
            {/* logout button */}
            {isMobile && (
              <Box onClick={() => refetch()} sx={{ cursor: "pointer" }}>
                {!isGuest && (
                  <Button variant="text" color="error">
                    <LogoutIcon />
                  </Button>
                )}

                {
                  // login button if he is a guest
                  isGuest && (
                    <Box>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor: "#EDD4D7",
                          color: "#A32A3C",
                          fontWeight: "bold",
                          "&:hover": {
                            backgroundColor: "#EDD4D7",
                            color: "#A32A3C",
                          },
                        }}
                        size="small"
                        onClick={() => navigate("/login")}
                      >
                        <Translator textKey="signin"></Translator>
                      </Button>
                    </Box>
                  )
                }
              </Box>
            )}
          </Box>
          {/* <AccountMenu /> */}

          {/* Guest login button */}
          {isGuest && !isMobile && (
            <Box>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#EDD4D7",
                  color: "#A32A3C",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#EDD4D7",
                    color: "#A32A3C",
                  },
                }}
                size="large"
                onClick={() => navigate("/login")}
              >
                <Translator textKey="signin"></Translator>
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      {!isMobile ? (
        <StyledDrawer>
          <Drawer
            variant="permanent"
            open={open}
            sx={{
              zIndex: 100,
              backgroundColor: "white",
            }}
          >
            <Divider />
            <List sx={{ height: "100%" }}>
              <ListItem
                disablePadding
                sx={{ display: "block", border: "none" }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      sx={{
                        // padding: 1,
                        borderRadius: "50%",
                        background: "#b2bec3",
                      }}
                    >
                      <Avatar
                        sx={{
                          width: 50,
                          height: 50,
                          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        }}
                      >
                        {user.profile_pic ? (
                          <img
                            src={user.profile_pic}
                            alt={user.name}
                            style={{
                              width: 50,
                              height: 50,
                              objectFit: "fill",
                            }}
                          />
                        ) : (
                          user.name && user.name[0]
                        )}
                      </Avatar>
                    </Box>
                  </ListItemIcon>
                  <Box
                    sx={{ width: open ? "100%" : "0px", overflow: "hidden" }}
                  >
                    <ListItemText primary={capitalize(user?.name || "")} />
                    <Typography variant="caption" display="block" gutterBottom>
                      {capitalize(user?.type || "")}
                    </Typography>

                    {isGuest && (
                      <>
                        <ListItemText primary="Guest" />
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                        >
                          Seeker
                        </Typography>
                      </>
                    )}
                  </Box>
                </ListItemButton>
              </ListItem>
              {/* <ListItem disablePadding sx={{ display: "block" }}>
            <Link to="/">
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "start" : "center",
                  px: 2.5,
                  "&:hover": {
                    background: "#ffe0e5",
                  },
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    marginInlineEnd: open ? 3 : "auto",
                    justifyContent: "start",
                  }}
                >
                  <HomeIcon className="menu-icon" />
                </ListItemIcon>
                <ListItemText
                  primary={translator("home")}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
          </ListItem> */}
              <IsSeeker>
                <StyledListItem disablePadding sx={{ display: "block" }}>
                  <Link to="/">
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "start" : "center",
                        px: 2.5,
                        "&:hover": {
                          background:
                            location.pathname === "/" ? "#A32A3C" : "#ffe0e5",
                        },
                        backgroundColor:
                          location.pathname === "/" ? "#A32A3C" : "transparent",
                        color: location.pathname === "/" ? "white" : "inherit",
                        "& .menu-icon": {
                          color:
                            location.pathname === "/" ? "white" : "#636e72",
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          marginInlineEnd: open ? 3 : "auto",
                          justifyContent: "start",
                        }}
                      >
                        <HomeIcon className="menu-icon" />
                      </ListItemIcon>
                      <ListItemText
                        primary={translator("home")}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </Link>
                </StyledListItem>
              </IsSeeker>
              {isGuest && (
                <StyledListItem disablePadding sx={{ display: "block" }}>
                  <Link to="/">
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "start" : "center",
                        px: 2.5,
                        "&:hover": {
                          background:
                            location.pathname === "/" ? "#A32A3C" : "#ffe0e5",
                        },
                        backgroundColor:
                          location.pathname === "/" ? "#A32A3C" : "transparent",
                        color: location.pathname === "/" ? "white" : "inherit",
                        "& .menu-icon": {
                          color:
                            location.pathname === "/" ? "white" : "#636e72",
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          marginInlineEnd: open ? 3 : "auto",
                          justifyContent: "start",
                        }}
                      >
                        <HomeIcon className="menu-icon" />
                      </ListItemIcon>
                      <ListItemText
                        primary={translator("home")}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </Link>
                </StyledListItem>
              )}

              <StyledListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "start" : "center",
                    px: 2.5,
                    "&:hover": {
                      background:
                        location.pathname === "/profile"
                          ? "#A32A3C"
                          : "#ffe0e5",
                    },
                    backgroundColor:
                      location.pathname === "/profile"
                        ? "#A32A3C"
                        : "transparent",
                    color:
                      location.pathname === "/profile" ? "white" : "inherit",
                    "& .menu-icon": {
                      color:
                        location.pathname === "/profile" ? "white" : "#636e72",
                    },
                  }}
                  onClick={() => handleGuestNavigation("/profile")}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      marginInlineEnd: open ? 3 : "auto",
                      justifyContent: "start",
                    }}
                  >
                    <PersonIcon className="menu-icon" />
                  </ListItemIcon>
                  <ListItemText
                    primary={translator("profile")}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </StyledListItem>

              {(type === "seeker" || isGuest) && (
                <StyledListItem disablePadding sx={{ display: "block" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "start" : "center",
                      px: 2.5,
                      "&:hover": {
                        background:
                          location.pathname === "/saved-jobs"
                            ? "#A32A3C"
                            : "#ffe0e5",
                      },
                      backgroundColor:
                        location.pathname === "/saved-jobs"
                          ? "#A32A3C"
                          : "transparent",
                      color:
                        location.pathname === "/saved-jobs"
                          ? "white"
                          : "inherit",
                      "& .menu-icon": {
                        color:
                          location.pathname === "/saved-jobs"
                            ? "white"
                            : "#636e72",
                      },
                    }}
                    onClick={() => handleGuestNavigation("/saved-jobs")}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        marginInlineEnd: open ? 3 : "auto",
                        justifyContent: "start",
                      }}
                    >
                      <BookmarkIcon className="menu-icon" />
                    </ListItemIcon>
                    <ListItemText
                      primary={translator("savedJobs")}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </StyledListItem>
              )}

              <StyledListItem disablePadding sx={{ display: "block" }}>
                <Link to="/jobs">
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "start" : "center",
                      px: 2.5,
                      "&:hover": {
                        background:
                          location.pathname === "/jobs" ? "#A32A3C" : "#ffe0e5",
                      },
                      backgroundColor:
                        location.pathname === "/jobs"
                          ? "#A32A3C"
                          : "transparent",
                      color:
                        location.pathname === "/jobs" ? "white" : "inherit",
                      "& .menu-icon": {
                        color:
                          location.pathname === "/jobs" ? "white" : "#636e72",
                      },
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        marginInlineEnd: open ? 3 : "auto",
                        justifyContent: "start",
                      }}
                    >
                      <WorkIcon className="menu-icon" />
                    </ListItemIcon>
                    <ListItemText
                      primary={translator("jobs")}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Link>
              </StyledListItem>

              <StyledListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "start" : "center",
                    px: 2.5,
                    "&:hover": {
                      background:
                        location.pathname === "/notifications"
                          ? "#A32A3C"
                          : "#ffe0e5",
                    },
                    backgroundColor:
                      location.pathname === "/notifications"
                        ? "#A32A3C"
                        : "transparent",
                    color:
                      location.pathname === "/notifications"
                        ? "white"
                        : "inherit",
                    "& .menu-icon": {
                      color:
                        location.pathname === "/notifications"
                          ? "white"
                          : "#636e72",
                    },
                  }}
                  onClick={() => handleGuestNavigation("/notifications")}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      marginInlineEnd: open ? 3 : "auto",
                      justifyContent: "start",
                    }}
                  >
                    <NotificationsIcon className="menu-icon" />
                  </ListItemIcon>
                  <ListItemText
                    primary={translator("notifications")}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </StyledListItem>

              {!isGuest && (
                <Box
                  sx={{
                    display: "block",
                    position: "absolute",
                    bottom: "2em",
                    left: 0,
                    right: 0,
                  }}
                >
                  <StyledListItem disablePadding sx={{ display: "block" }}>
                    <ListItemButton
                      onClick={() => refetch()}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "start" : "center",
                        px: 2.5,
                        "&:hover": {
                          background: "#ffe0e5",
                        },
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          marginInlineEnd: open ? 3 : "auto",
                          justifyContent: "start",
                        }}
                      >
                        <LogoutIcon className="menu-icon" />
                      </ListItemIcon>
                      <ListItemText
                        primary={translator("logout")}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </StyledListItem>
                </Box>
              )}
              <StyledListItem
                disablePadding
                sx={{
                  display: "block",
                  background: "#ffffff",
                  border: "none",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "absolute",
                  bottom: 0,
                }}
              >
                <Box
                  sx={{
                    minHeight: 28,
                    justifyContent: open ? "initial" : "center",
                    px: 0,
                    display: "flex",
                  }}
                >
                  <Box
                    sx={{
                      minWidth: 0,
                      justifyContent: "center",
                      border: "none",
                      m: "auto",
                      position: "absolute",
                      bottom: 0,
                    }}
                  >
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      sx={{
                        display: open ? "none" : "flex",
                        textAlign: "center",
                        m: "auto",
                        width: "fit-content",
                        overflow: "hidden",
                      }}
                    >
                      &copy; {dayjs().year()}
                    </Typography>
                  </Box>
                  <Typography
                    variant="caption"
                    display="block"
                    gutterBottom
                    sx={{
                      display: open ? "flex" : "none",
                      textAlign: "center",
                      m: "auto",
                      width: "fit-content",
                      overflow: "hidden",
                      width: "100%",
                      justifyContent: "center",
                    }}
                  >
                    Copyrights &copy; {dayjs().year()}
                  </Typography>
                </Box>
              </StyledListItem>
            </List>
            <Divider />
          </Drawer>
        </StyledDrawer>
      ) : (
        <BottomNavigation
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1300,
            backgroundColor: theme.palette.background.paper,
          }}
        >
          {(type === "seeker" || isGuest) && (
            <BottomNavigationAction
              label={translator("home")}
              icon={
                <HomeIcon
                  className="menu-icon"
                  sx={{ color: location.pathname === "/" ? "#fff" : "#636e72" }}
                />
              }
              component={Link}
              to="/"
              sx={{
                backgroundColor: location.pathname === "/" && "#A32A3C",
                minWidth: 0,
                justifyContent: "start",
              }}
            />
          )}
          <BottomNavigationAction
            label={translator("profile")}
            icon={
              <PersonIcon
                className="menu-icon"
                sx={{
                  color: location.pathname === "/profile" ? "#fff" : "#636e72",
                }}
              />
            }
            component={Link}
            to={!isGuest && "/profile"}
            onClick={() => isGuest && setSignInPrompt(true)}
            sx={{
              backgroundColor: location.pathname === "/profile" && "#A32A3C",
              minWidth: 0,
              justifyContent: "start",
            }}
          />
          {(type === "seeker" || isGuest) && (
            <BottomNavigationAction
              label={translator("savedJobs")}
              icon={
                <BookmarkIcon
                  className="menu-icon"
                  sx={{
                    color:
                      location.pathname === "/saved-jobs" ? "#fff" : "#636e72",
                  }}
                />
              }
              component={Link}
              to={!isGuest && "/saved-jobs"}
              onClick={() => isGuest && setSignInPrompt(true)}
              sx={{
                backgroundColor:
                  location.pathname === "/saved-jobs" && "#A32A3C",
                minWidth: 0,
                justifyContent: "start",
              }}
            />
          )}

          <BottomNavigationAction
            label={translator("jobs")}
            icon={
              <WorkIcon
                className="menu-icon"
                sx={{
                  color: location.pathname === "/jobs" ? "#fff" : "#636e72",
                }}
              />
            }
            component={Link}
            sx={{
              backgroundColor: location.pathname === "/jobs" && "#A32A3C",
              minWidth: 0,
              justifyContent: "start",
            }}
            to="/jobs"
          />
          <BottomNavigationAction
            label={translator("notifications")}
            icon={
              <NotificationsIcon
                className="menu-icon"
                sx={{
                  color:
                    location.pathname === "/notifications" ? "#fff" : "#636e72",
                }}
              />
            }
            component={Link}
            to={!isGuest && "/notifications"}
            onClick={() => isGuest && setSignInPrompt(true)}
            sx={{
              backgroundColor:
                location.pathname === "/notifications" && "#A32A3C",
              minWidth: 0,
              justifyContent: "start",
            }}
          />
        </BottomNavigation>
      )}

      <Box
        component="main"
        sx={{ width: "100%", paddingInlineStart: !isMobile && "4em" }}
      >
        {/* <DrawerHeader />
        <Layout /> */}
        {children}
      </Box>
      <SignInPrompt
        open={signInPrompt}
        handleClose={() => setSignInPrompt(false)}
      />
    </Box>
  );
};

export default Slider;
