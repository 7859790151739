import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  totalJobs: 0,
  totalViews: 0,
  openedJobs: 0,
  totalApplicants: 0,
};

const metricsSlice = createSlice({
  name: "metrics",
  initialState,
  reducers: {
    setMetrics: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setMetrics } = metricsSlice.actions;
export default metricsSlice.reducer;
