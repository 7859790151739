import React, { useMemo } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useLocalization } from "../lang";

const ComboBox = ({
  defaultValue,
  options = [],
  labelKey,
  error,
  size,
  ...props
}) => {
  const { translator } = useLocalization();

  const dv = useMemo(() => {
    const option = options.find((o) => {
      if (o.id == 0) return o.id == 0;
      return o.id == defaultValue;
    });
    const d = option ? option.label : undefined;
    return d;
  }, [options, defaultValue]);

  return (
    <Autocomplete
      options={options}
      sx={{ width: "100%" }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          label={translator(labelKey)}
          size={size || "small"}
          helperText={error || undefined}
          error={!!error}
          placeholder={dv}
          InputLabelProps={{ shrink: dv && true }}
        />
      )}
      {...props}
    />
  );
};

export default ComboBox;
